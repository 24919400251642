.content-images-port{
  width: 100%;
  height: 508px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 55px;
}

.img-desk{
  width: 665px;
  image-rendering: pixelated;
}

.img-mobile{
  image-rendering: optimizeQuality;

}

@media(max-width: 900px){
  .content-images-port{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .img-desk{
    max-width: 90%;
  }
  .img-mobile{
    max-width: 90%;
  }
}