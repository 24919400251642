.content-footer{
  height: 192px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: var(--dark);
}

.logo-footer {
  width: auto;
  max-width: 125px;
  margin-left: 45px;
}

.contatos {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  width: 100%;
}

.contatos div:last-child{
  margin-left: 45px;
}

.row-dados{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-right: 45px;

  min-width: 463px;
}

.p-red {
  color: var(--primary);
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: start;
}

.p-light {
  color: var(--light);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: start;
}

.direitos{
  width: 100%;
  display: flex;
  align-items: flex-start;
}

@media(max-width: 900px){
  .content-footer{
    padding: 1rem;
    overflow: hidden;
    flex-direction: column;
  }
  .logo-footer {
    max-width: 105px;
    margin-left: 0px;
    margin-bottom: 15px;
  }
  .contatos {
    margin-bottom: 10px;
  }

  
  .p-light,.p-red{
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
  }
  .row-dados{
    display: flex;
    flex-direction: column;
    margin-right: 0px;
    min-width: auto;
  }
  .direitos{
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .direitos{
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
}