.cardOpen{
  display: flex;
  flex-direction: column;

  align-items: center;

  width: 90%;
  height: 529px;

  position: fixed;
  bottom: 0px;
  left: 5%;
  border-radius: 8px 8px 0px 0px;
  background-color: #fff;
  z-index: 2000;
}

.cardOff{
  display: none;
}

.cardOpen h3{
  font-weight: normal;
  font-size: 19px;
  line-height: 120%;
  color: var(--secondary--1);
}

.cardOpen h5{
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: var(--secondary);
}

.button-close {
  position: absolute;
  width: 35px;
  height: 35px;
  right: 35px;
  top: 35px;
  background-color: transparent;
  border: transparent;

  cursor: pointer;
}

.div-content-form{
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

}
.actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.content-steps-contato{
  width: 50px;
  max-height: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.form-contato{
  min-width: 614px;
  max-height: 380px;
  margin-top: 35px;
  margin-right: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-contato div{
  display: flex;
  margin: 5px;
}
.form-contato .textField-email{
  margin-left: 10px;
  margin-right: 0px;
}

.line-step{
  width: 0px;
  height: 75px;
  background: transparent;
}

.line-step[data-display='show']{
  border: 1px solid var(--primary--1);
}

.content-step-two{
  margin-top: 55px;
}

.row-card-works{
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.content-text-area{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-area{
  width: 622px;
  height: 210px;
  margin-top: 70px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 13px 19px 13px 13px;

  border: 1px solid #73726D;
  box-sizing: border-box;
  border-radius: 6px;
}

.button-voltar{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  margin-right: 10px;
  height: 49px;
  background: transparent;
  border: none;
  border-radius: 98px;
  color: #000;
  cursor: pointer;

  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
}

.button-voltar:hover{
  text-decoration: underline;
}

@media(max-width: 900px){
  .cardOpen{  
    align-items: center;
    width: 95vw;
    height: 88%;
    position: fixed;
    bottom: 0px;
    left: 2%;
  }
  .button-close {
    width: 27px;
    height: 15px;
    right: 15px;
    top: 10px;
  }

  .div-content-form{
    width: 90vw;
    height: 85%;
    max-width: 1000px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

  }
  .form-contato{
    margin-top: 15px;
    min-width: 90%;
  }

  .form-contato div{
    display: flex;
    flex-direction: column;
    max-width: 95%;
  }

  .content-steps-contato{
    width: 50px;
    max-height: 100%;
    display: flex;
    margin-top: 50px;
  }
  
  .form-contato .textField-email{
    margin-left: 10px;
    max-width: 90%;
    margin-right: 0px;
  }

  .content-text-area{
    width: 90%;
  }
  
  .text-area{
    width: 90%;
    max-width: 622px;
    height: 300px;
    margin-top: 55px;
    margin-bottom: 40px;
  }
  .content-step-two{
    margin-top: 20px;
  }
  .row-card-works{
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
  }

  
}

@media(max-height: 550px){
  .form-contato{
    overflow-y: scroll;
    height: 85%;
  }
  .content-step-two{
    overflow-y: scroll;
    height: 85%;
  }
  .content-text-area{
    overflow-y: scroll;
    height: 85%;
  }
}