.content-cardTypeWork{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;

  width: 370px;
  height: 100px;

  background: #F7F7F7;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-left: 30px;

  cursor: pointer;
}

.content-cardTypeWork[data-theme="select"]{
  background: var(--secondary--1);
}

.title-cardTypeWork[data-theme="select"],
.text-cardTypeWork[data-theme="select"]{
  color: #F7F7F7;
}

.title-cardTypeWork{
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  
  color: #000000;
}

.text-cardTypeWork{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
}

@media(max-width: 900px){
  .content-cardTypeWork{
    width: 90%;
    height: 83px;
    padding: 10px 10px;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  
  .title-cardTypeWork{
    font-size: 14px;
  }
  
  .text-cardTypeWork{
    font-size: 14px;
  }
  
}