.content-navbar {
  width: 100%;
  height: 79px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.66) 0%,
    rgba(53, 53, 53, 0) 100%
  );
  border: none;

  position: absolute;
  z-index: 1000;
}

.content-navbar-black {
  width: 100%;
  height: 79px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: var(--dark);
  border: none;

  position: fixed;
  top: 0;

  z-index: 1000;
}

.logoDWM {
  width: auto;
  max-width: 105px;
  margin-left: 25px;
}
.div-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  padding-right: 25px;
}

@media (max-width: 900px) {
  .content-navbar {
    width: 100%;
    padding-left: 0px;
  }
  .content-navbar-black {
    width: 100%;
    padding-left: 0px;
  }

  .logoDWM {
    margin: 0;
  }

  .logoDWM {
    margin-left: 10px;
    width: 90px;
    height: auto;
  }

  .div-actions {
    padding-right: 0px;
  }
  .div-actions svg {
    font-size: 24px;
    margin: 0 10px 0 15px;
  }

  .icon-menu {
    margin-left: 8px;
  }
  .icon-menu[data-deg="90"] {
    transform: rotateZ(90deg);
  }
}
