a {
  text-decoration: none;
}
.content-card-row {
  display: flex;
  flex-direction: row;

  width: 499px;
  height: 136px;
  border-radius: 6px;
  background: var(--light);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  margin: 40px 0px;

  cursor: pointer;
  transition: 0.4s;
}

.content-card-row:hover {
  transform: scale(1.03);
  transition: 0.4s;
}

.content-card-row-div-image {
  min-width: 136px;
  height: 136px;

  background: var(--secondary--1);
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.content-card-column {
  display: flex;
  flex-direction: column;

  width: 211px;
  min-height: 300px;

  background: var(--light);
  border-radius: 6px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);

  margin: 0 38px;
  cursor: pointer;
  transition: 0.4s;
}

.content-card-column:hover {
  transform: scale(1.03);
  transition: 0.4s;
}
.content-card-column-div-image {
  min-height: 150px;

  background: var(--secondary--1);
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.div-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  padding: 14px;
}

.p-bold {
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 14px;
  color: #000000;
}

.p-regular {
  font-size: 16px;
  line-height: 150%;
  text-align: center;

  color: #000000;
}

@media (max-width: 900px) {
  .content-card-row {
    width: 95%;
    max-width: 499px;
    height: 93px;
    margin: 7px 0.5rem;
  }

  .content-card-row-div-image {
    height: 93px;
  }

  .content-card-column {
    display: flex;
    flex-direction: row;

    width: 95%;
    max-width: 499px;
    height: 93px;
    min-height: 93px;
    border-radius: 6px;
    background: var(--light);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    margin: 7px 0.5rem;

    cursor: pointer;
  }

  .content-card-column-div-image {
    height: 93px;
    min-height: 93px;
    background: var(--secondary--1);
    border-radius: 6px;
    padding-left: 3px;
    padding-right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .div-text {
    padding: 8px;
  }

  .p-bold {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .p-regular {
    font-size: 12px;
  }
}
