.content-step-orcamento {
  width: 422px;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title-step {
  width: 205px;
  height: 23.27px;

  font-weight: normal;
  font-size: 19px;
  line-height: 120%;

  color: var(--secondary--1);

  margin-top: -10px;
  margin-bottom: 30px;
}

.paragrafo-step{
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  width: 392px;
  height: 72.85px;
  color: black;
}

@media(max-width: 900px){
  .content-step-orcamento {
    width: 100%;
    height: auto;
  }
  .title-step {
    width: 90%;
    font-size: 16px;
    line-height: 150%;
  }


  .paragrafo-step{
    font-size: 14px;
    line-height: 150%;
    width: 90%;
    max-width: 392px;
    height: auto;
  }
}