:root {
  --primary--1: #FFA457;
  --primary: #FF7400;
  --primary-1: #D16D00;
  --secondary--1: #6A6965;
  --secondary: #000000;
  --light: #ffffff;
  --light-1: #F2F2F2;
  --dark--1: #565867;
  --dark: #202026;
  --sucess: #37E81A;
  --danger: #CC2727;
}
html {
  scroll-behavior: smooth;
}
body{
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  display: none;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 20px;
  border: none;
}

*{
  font-family: Open Sans;
  margin: 0;
  padding: 0;

}

h1,h2,h3,h4,h5{
  font-weight: 400;
  font-style: normal;
}

h1{
  font-size: 47px;
  line-height: 51.7px;
}
h2{
  font-size: 39px;
  line-height: 42.9px;
}
h3{
  font-size: 27.7px;
  line-height: 30.47px;
}
h5{
  font-size: 19px;
  line-height: 22.8px;
}