.list-work{
  margin-top: 30px;
  margin-left: 70px;
}

.item-list{
  display: flex;
  flex-direction: row;
  margin-bottom: 17px;
}

.icon-check{
  margin-right: 14px;
}

.item-list p {
  text-align: start;
}

@media(max-width: 700px){
  .list-work{
    margin-top: 30px;
    margin-left: 20px;
  }
}