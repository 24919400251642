.content-steps{
  position: relative;

  display: flex;
  flex-direction: column;

  left: 0px;
}

.arrow-up{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-bottom: 5px;
  background-image: url("../../assets/images/arrowDown.svg");
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(180deg);
  cursor: pointer;
}
.arrow-down{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-top: 5px;
  background-image: url("../../assets/images/arrowDown.svg");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.arrow-up:hover, 
.arrow-down:hover{
  width: 22px;
  height: 22px;
}

.step-circle{
  width: 16px;
  height: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 50%;
  cursor: pointer;
  background: var(--secondary--1);
}

.step-circle[data-color="select"]{
  background: var(--primary);
  width: 22px;
  height: 22px;
}

@media(max-width: 900px){
  .content-steps{
    width: auto;
    flex-direction: row;  
    left: 0px;
  }
  .step-circle{
    width: 16px;
    height: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 5px;
    border-radius: 50%;
    
    background: var(--secondary--1);
  }
  .arrow-up{
    margin-bottom: 0px;
    margin-right: 5px;
    transform: rotate(90deg);
  }
  .arrow-down{
    margin-top: 0px;
    margin-left: 5px;
    transform: rotate(-90deg);
  }
}