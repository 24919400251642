.content-button-contato{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;

  max-width: 251px;
  height: 49px;
  background: var(--primary);
  
  border: none;
  border-radius: 98px;

  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  color: #fff;

  cursor: pointer;
}

.content-button-contato:hover{
  background: var(--primary-1);
}
.content-button-contato img{
  margin-right: 10px;
}
.content-button-contato p {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

@media(max-width: 900px){

  .content-button-contato{
    height: 49px;
  }
  .content-button-contato p {
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
  }
}