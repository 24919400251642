.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App main {
  width: 100%;
  align-items: center;
}

.App .home {
  height: 100vh;
  background-image: url(./assets/images/backgroundHome.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: var(--light);
}

.App .home h1 {
  z-index: 10;
}

.App .home .home-gradiente {
  height: 100vh;
  width: 100%;
  position: absolute;
  background: linear-gradient(#0a0a0a50, rgba(255, 255, 255, 0.103));
}

.App .button-wpp {
  background-color: transparent;
  border: none;

  position: fixed;
  bottom: 125px;
  left: 25px;
  cursor: pointer;
  filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.2));
}

.App .button-wpp:hover {
  filter: var(--primary);
}

.App .scroll {
  background-color: transparent;
  border: none;

  position: fixed;
  bottom: 125px;
  right: 25px;
  cursor: pointer;
}
.scroll[data-up="up"] {
  transform: rotate(180deg);
}

main .oque-e-dwm {
  height: 631px;
}

.div-oque-dwm {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 457px;
}

.div-oque-dwm .img-oque-e-DWM {
  width: 535px;
  height: 276px;

  margin: 0 25px;
}

.div-oque-dwm .img-oque-e-DWM .div-paragrafo-oque-dwm {
  width: 703px;
  height: 145px;
  background-size: contain;
}

.paragrafo-oque-dwm {
  margin-bottom: 25px;
  max-width: 703px;
  font-style: normal;
  text-align: start;
}

.servicos {
  height: 600px;
  display: flex;
  flex-direction: column;
  background: var(--light-1);
}

.row-div-cards {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 478px;
}

.info-projetos {
  height: 1436px;

  display: flex;
  flex-direction: column;

  padding-top: 80px;
  align-items: center;
}

.div-actions-works {
  display: flex;
  flex-direction: row;
  width: 100%;

  align-items: center;
  justify-content: center;
  margin-bottom: 79px;
}

.detalhes-work-um {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 70px;
}
.img-detalhes-work {
  max-width: 600px;
  margin: 0 40px;
}
.detalhes-work-title {
  font-weight: bold;
  font-size: 23px;
  line-height: 120%;
  color: var(--secondary--1);
  text-align: start;
  margin-bottom: 45px;
}
.detalhes-work-p {
  width: 587px;
  height: 75px;

  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: start;
  color: black;
}

.div-contratacao {
  background: var(--light-1);
  height: 570px;
}

.div-steps {
  display: flex;
  flex-direction: row;
  height: 452px;
  align-items: center;
  justify-content: center;
}

.div-metodologia {
  height: 1650px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div-metodologia .columns-metodologia {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.image-arrow-metodologia {
  display: none;
}

.div-portifolio {
  display: flex;
  flex-direction: column;

  background: var(--light-1);
  padding-bottom: 40px;
  margin-top: 20px;
  transition: 0.5s;
}

.div-portifolio[data-bg="#22CECB"]:hover {
  background-color: #22cecb;
}
.div-portifolio[data-bg="#48D904"]:hover {
  background-color: #48d904;
}
.div-portifolio[data-bg="#001E62"]:hover {
  background-color: #001e62;
}
.div-portifolio[data-bg="#96A8CE"]:hover {
  background-color: #96a8ce;
}
.div-portifolio[data-bg="#252323"]:hover {
  background-color: #252323;
}
.div-portifolio[data-bg="#FFFFFF"]:hover {
  background-color: #ffffff;
}

.div-portifolio[data-bg="#001E62"]:hover h3,
.div-portifolio[data-bg="#252323"]:hover h3 {
  color: #fff !important;
}
.div-portifolio[data-bg="#001E62"]:hover h5,
.div-portifolio[data-bg="#252323"]:hover h5 {
  color: #fff !important;
}

.content-portifolio {
  padding: 0rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 900px) {
  .App main .home h1 {
    font-weight: normal;
    font-size: 27.7px;
    line-height: 110%;
  }

  main .oque-e-dwm {
    height: auto;
  }
  .div-oque-dwm {
    flex-direction: column-reverse;
    align-items: center;
    height: auto;
  }
  .div-oque-dwm .img-oque-e-DWM {
    width: 90%;
    height: 188px;
    margin: 0px;
  }

  .div-oque-dwm .div-paragrafo-oque-dwm {
    width: 90%;
    height: auto;
    margin-top: 45px;
    background-size: contain;
  }

  .div-oque-dwm .div-paragrafo-oque-dwm .paragrafo-oque-dwm {
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
  }

  .servicos {
    height: 720px;
  }
  .servicos .row-div-cards {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 45px;
  }
  .servicos .row-div-cards .div-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .info-projetos {
    width: 100vw;
    padding-top: 45px;
  }

  .info-projetos h3 {
    font-weight: normal;
    font-size: 21px !important;
    line-height: 120% !important;
    color: var(--secondary--1) !important;
  }
  .info-projetos .detalhes-work-um {
    flex-direction: column;
    margin-top: 45px;
  }
  .info-projetos .div-actions-works {
    margin-bottom: 45px;
  }

  .info-projetos .content-info-projetos {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .info-projetos .img-detalhes-work {
    width: 269px;
    height: 265px;
    margin: 0 40px;
  }

  .info-projetos .detalhes-work-title {
    width: 100%;
    font-size: 19px;
    color: var(--secondary--1);
    text-align: start;
    margin-bottom: 25px;
    margin-top: 45px;
  }
  .info-projetos .detalhes-work-p {
    width: 100%;
    height: 85px;

    font-size: 14px;
  }
  .div-contratacao {
    margin-top: 60px;
    height: auto;
  }

  .div-contratacao .div-steps {
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: center;
  }

  .div-contratacao .image-circle {
    height: 1px;
    background-color: var(--primary);
    width: 80%;
    max-width: 300px;
    margin: 45px 0;
  }

  .div-metodologia {
    height: auto;
  }
  .image-arrow-metodologia {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0px;
  }
  .div-metodologia .columns-metodologia {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .div-metodologia .image-metodologia {
    display: none;
  }

  .App main .button-wpp {
    bottom: 50px !important;
    left: 10px !important;
  }
  .scroll {
    bottom: 50px !important;
    right: 10px !important;
  }

  .content-portifolio {
    flex-direction: column;
  }
}
