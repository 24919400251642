.content-button-navbar{
  border: none;
  height: 100%;
  width: 140px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
    
}

.content-button-navbar:hover{
  color: var(--primary-1);
  border-bottom: 3px solid var(--primary-1);
  cursor: pointer;
}

.content-button-navbar p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@media(max-width: 900px){
  .content-button-navbar{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}