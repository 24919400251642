.div-steps-tecnologia{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row-tecnologia{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.circle-row{
  width: 282.1px;
  height: 282.1px;
  border-radius: 50%;
  
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 50px;
  margin-bottom: 60px;
  margin-right: 100px;
  color: #fff;
  background: var(--secondary);
}

.circle-row:last-child{
  margin-right: 0px;
}

.content-steps-tecnologia{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.steps-tecnologia{
  width: 120.24px;
  height: 5px;
  border-radius: 2px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 45px;
  background-color: var(--secondary--1);
  cursor: pointer;
}

.steps-tecnologia[data-select="select"]{
  background-color: #FF7400;
}

@media(max-width: 900px){
  .content-steps-tecnologia{
    width: 100%;  
  }
  .steps-tecnologia{
    width: 40px;
    height: 5px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 35px;
  }

  .circle-row{
    width: 110px;
    height: 110px;
    border-radius: 50%;
    margin-right: 25px;
  }
  
  .circle-row:last-child{
    margin-right: 0px;
  }
}
