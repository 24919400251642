.content-alert{
  display: none;

  padding: 10px 30px;

  position: absolute;
  width: 504px;
  max-width: 100%;
  height: 40px;
  top: -80px;

  color: #ffffff;
  z-index: 1999;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
}
.content-alert[data-display="show"]{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.content-alert[data-color="success"]{
  background: rgba(55, 232, 26, 0.95);
}
.content-alert[data-color="error"]{
  background: rgba(232, 26, 26, 0.95);
}

@media(max-width: 900px){
  .content-alert{
    max-width: 85%;
    top: -50px;

    font-weight: bold;
    font-size: 12px;
    line-height: 120%;
  }
}