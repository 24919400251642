.content-title-page {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

div[data-margin="104"] {
  padding-top: 104px;
}
div[data-margin="54"] {
  padding-top: 54px;
}
div[data-margin="42"] {
  padding-top: 42px;
}
div[data-margin="25"] {
  padding-top: 25px;
}

.line {
  width: 50.5px;
  border: 1px solid var(--primary);
}

.titles {
  margin: 0px 31.5px;
}
.titles h3 {
  font-weight: normal;
  font-size: 27.7px;
  line-height: 110%;
  color: var(--dark);
}

.titles h5 {
  font-weight: normal;
  font-size: 19px;
  line-height: 120%;
  color: var(--secondary);
}
.h3Bold {
  margin-top: 10px;
  font-weight: bold;
}

.paragrafo {
  margin-top: 15px;
}

@media (max-width: 900px) {
  .titles h3 {
    font-size: 23px;
    line-height: 120%;
  }

  .titles h5 {
    font-size: 15px;
    line-height: 120%;
  }
}
