.content-menu{
  width: 161px;
  height: 169px;
  background: var(--primary);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;

  position: absolute;
  top: 10px;
  right: 38px;
  z-index: 1999;
}