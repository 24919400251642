.content-card-metodologia{
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 399px;
  height: 363px;
  padding: 1rem;
  margin-bottom: 40px;
}

.content-card-metodologia[data-margin="140"]{
  margin-top: 140px;
}
.content-card-metodologia[data-margin="280"]{
  margin-top: 280px;
}

.content-card-metodologia h5{
  height: 23px;
  font-weight: normal;
  font-size: 19px;
  text-align: start;
  line-height: 120%;
  margin-top: 20px;
  margin-bottom: 10px;
  color: var(--secondary--1);
}

.content-card-metodologia p{
  max-width: 383px;
  height: 72px;
  text-align: start;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: var(--dark);
}

@media(max-width: 900px){
  .content-card-metodologia[data-margin="140"]{
    margin-top: 40px;
  }
  .content-card-metodologia[data-margin="280"]{
    margin-top: 40px;
  }

  .content-card-metodologia{  
    width: 90%;
    height: 363px;
    padding: 1rem;
  }

}