.content-metodologia{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  margin-top: 100px;
}

.div-text-metodologia{
  display: flex;
  flex-direction: column;
  width: 50%;
  overflow: hidden;
  height: 448px;
  margin-left: 30px;
  z-index: 2;
}

.title-metodologia{
  width: 615px;
  font-weight: bold;
  font-size: 23px;
  line-height: 120%;
  color: var(--secondary--1);
  margin-top: 15px;
  text-align: start;

}

.text-metodologia{
  width: 556px;
  margin-top: 45px;

  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: start;
}

.image-metodologia[data-image='200']{
  margin-left: -200px;
  z-index: 1;
}

.image-metodologia[data-image='220']{
  margin-left: -220px;
  z-index: 1;
}

.image-metodologia[data-image='60']{
  margin-left: -60px;
  z-index: 1;
}

.image-metodologia[data-image='190']{
  margin-left: -190px;
  z-index: 1;
}

.image-metodologia[data-image='110']{
  margin-left: -110px;
  z-index: 1;
}

@media(max-width: 900px){
  .content-metodologia{
    flex-direction: column-reverse;
    padding: 0 15px;
    margin-top: 35px;
  }

  .div-text-metodologia{
    width: 100%;
    height: auto;
    margin-left: 0px;
    z-index: 2;
  }
  
  .title-metodologia{
    width: 100%;
    height: 69px;
    left: 0px;
    color: var(--secondary--1);
    margin-top: 15px;
    text-align: start;
    font-weight: bold;
    font-size: 19px;
    line-height: 120%;
  }
  
  .text-metodologia{
    width: 100%;
    margin-top: 45px;
    font-size: 13px;
  }

  .image-metodologia{
    width: 100%;
    height: 194px;
  }

  .image-metodologia[data-image='200']{
    margin-left: 0px;
    z-index: 1;
  }

  .image-metodologia[data-image='220']{
    margin-left: 0px;
    z-index: 1;
  }

  .image-metodologia[data-image='60']{
    margin-left: 0px;
    z-index: 1;
  }

  .image-metodologia[data-image='190']{
    margin-left: 0px;
    z-index: 1;
  }

  .image-metodologia[data-image='110']{
    margin-left: 0px;
    z-index: 1;
  }
}