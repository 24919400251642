.content-step{
  width: 50px;
  height: 50px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  font-size: 23px;
  line-height: 120%;
  cursor: pointer;
}

.content-step[data-color='primary']{
  background: var(--primary);
}

.content-step[data-color='primary--1']{
  background: var(--primary--1);
}