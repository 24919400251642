.button-work{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 30px;

  height: 34px;

  background: var(--light);
  border: none;
  border-radius: 52px;

  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: black;
}

.name-button:hover{
  font-weight: bold;
  cursor: pointer;
}

.button-work[data-bg="select"]{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 30px;
  border: none;
  height: 34px;

  border-radius: 52px;
  background: var(--primary);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: bold;
}

@media(max-width: 900px){

  .button-work{
    width: 20px;
    height: 20px;
    padding: 0px;
    border-radius: 50%;
    margin: 0px 10px;
    background: var(--secondary--1);
  }
  .button-work[data-bg="select"]{
    width: 20px;
    height: 20px;
    padding: 0px;
    border-radius: 50%;
    margin: 0px 10px;
    background: var(--primary);
  }
  .button-work .name-button{
    display: none;
  }
}

/* .button-work:hover{
  background: var(--primary);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
} */